<template>
  <section class="column section-height" ref="force">
    <h1>Fuerza</h1>
    <div v-for="(subcategory, name, index) in statisticData" :key="index" class="columns is-centered">
      <div class="column is-10 has-text-centered">
        <!-- '0-0' contains default name, subcategories from possession only has one possible name -->
        <span>{{ subcategory.names[subcategoryName] || subcategory.names['0-0'] }}</span>
        <div class="clickable" @click="showModal($event, subcategory)">
          <Force :postmatch="postmatch(subcategory)" :live="live(subcategory)" />
        </div>
      </div>
    </div>
    <FuerzaTooltip
      :dataLocal="tooltip.dataLocal"
      :dataVisiting="tooltip.dataVisiting"
      :names="tooltip.names"
      :keyName="tooltip.keyName"
      :leagueAvg="tooltip.leagueAvg"
      :left="tooltip.left"
      :show.sync="tooltip.show"
      :x="tooltip.x"
      :y="tooltip.y"
      :width="width"
    >
    </FuerzaTooltip>
  </section>
</template>

<script>
import Force from '@/components/Elements/NextMatches/Fuerza';
import FuerzaTooltip from '@/components/Elements/NextMatches/FuerzaTooltip';
import { mapGetters, mapState } from 'vuex';
import { avg } from '@/utils/league';

export default {
  name: 'Fuerza',
  components: {
    Force,
    FuerzaTooltip,
  },
  data: function() {
    return {
      tooltip: {
        show: false,
        dataLocal: {},
        dataVisiting: {},
        names: {},
        keyName: '',
        leagueAvg: '',
        left: true,
        x: 0,
        y: 0,
      },
      width: 450,
    };
  },
  computed: {
    ...mapState('nextGeneral', ['currentSeasonTeams']),
    ...mapGetters('nextGeneral', [
      'statisticData',
      'homeTeamStatisticsType',
      'visitingTeamStatisticsType',
      'subcategoryName',
      'showPostMatchData',
      'showLiveData',
      'accomplished',
    ]),
    totalTeams() {
      return this.currentSeasonTeams.length;
    },
  },
  methods: {
    postmatch(subcategory) {
      if (!this.showPostMatchData) {
        const homeTeamRanking =
          this.homeTeamStatisticsType === 'accomplished'
            ? subcategory.homeTeam[this.homeTeamStatisticsType].ranking
            : this.totalTeams + 1 - subcategory.homeTeam[this.homeTeamStatisticsType].ranking;
        const visitingTeamRanking =
          this.visitingTeamStatisticsType === 'accomplished'
            ? subcategory.visitingTeam[this.visitingTeamStatisticsType].ranking
            : this.totalTeams + 1 - subcategory.visitingTeam[this.visitingTeamStatisticsType].ranking;
        return (100 * (homeTeamRanking - visitingTeamRanking)) / (this.totalTeams - 1);
      } else {
        const totalCat =
          subcategory.homeTeam[this.homeTeamStatisticsType].totalPostGame +
          subcategory.visitingTeam[this.visitingTeamStatisticsType].totalPostGame +
          1;
        const homeTeamRanking = totalCat - subcategory.homeTeam[this.homeTeamStatisticsType].totalPostGame;
        const visitingTeamRanking = totalCat - subcategory.visitingTeam[this.visitingTeamStatisticsType].totalPostGame;
        return (100 * (homeTeamRanking - visitingTeamRanking)) / totalCat;
      }
    },
    live(subcategory) {
      return (
        (100 /
          (subcategory.homeTeam[this.homeTeamStatisticsType].totalLive +
            subcategory.visitingTeam[this.visitingTeamStatisticsType].totalLive)) *
        (subcategory.homeTeam[this.homeTeamStatisticsType].totalLive ===
        subcategory.visitingTeam[this.visitingTeamStatisticsType].totalLive
          ? 0
          : subcategory.homeTeam[this.homeTeamStatisticsType].totalLive >
            subcategory.visitingTeam[this.visitingTeamStatisticsType].totalLive
          ? // if home team live data is bigger then percentage must be negative, to draw line on the left side.
            -1 * subcategory.homeTeam[this.homeTeamStatisticsType].totalLive
          : subcategory.visitingTeam[this.visitingTeamStatisticsType].totalLive)
      );
    },
    showModal(event, subcategory) {
      this.tooltip.dataLocal = subcategory.homeTeam[this.homeTeamStatisticsType];
      this.tooltip.dataVisiting = subcategory.visitingTeam[this.visitingTeamStatisticsType];
      this.tooltip.names = subcategory.names;
      this.tooltip.keyName = this.subcategoryName;
      this.tooltip.leagueAvg = this.leagueAvg(subcategory);
      const POSITION = this.$refs.force.getBoundingClientRect();
      this.tooltip.x = POSITION.right + (POSITION.left - POSITION.right - this.width) / 2;
      this.tooltip.y = event.pageY > 550 ? event.pageY - 250 : event.pageY;
      this.tooltip.show = true;
    },
    leagueAvg(subcategory) {
      return avg(subcategory, this.accomplished);
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
.clickable:hover {
  border-radius: 5px;
  background-color: #e9f1e3;
}
</style>
