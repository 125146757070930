<template>
  <Tooltip :show="show" :show-close="isMobile" :x="x" :y="y" :left="left" @close="onClose" :width="width">
    <template v-slot:header>
      {{ names[keyName] }}
    </template>
    <template v-slot:body>
      <table style="width:100%">
        <tr>
          <td width="49%" class="td-team-info">
            <div class="team-info local">
              <TeamFuerzaTooltip
                :team="localTeam"
                :data="dataLocal"
                :teamLeader="localCategoryTeamLeader"
                :name="localCategoryName"
                :showLiveData="showLiveData"
                :leagueAvg="leagueAvg"
              />
            </div>
          </td>
          <td width="2%" class="td-v-bar">
            <div class="v-bar" />
          </td>
          <td width="49%" class="td-team-info">
            <div class="team-info visiting">
              <TeamFuerzaTooltip
                :team="visitingTeam"
                :data="dataVisiting"
                :teamLeader="visitingCategoryTeamLeader"
                :name="visitingCategoryName"
                :showLiveData="showLiveData"
                :leagueAvg="leagueAvg"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <div class="conclusion"><b>Conclusión: </b>{{ conclusion }}</div>
          </td>
        </tr>
      </table>
    </template>
  </Tooltip>
</template>

<script>
import Tooltip from '@/components/Elements/NextMatches/Tooltip';
import TeamFuerzaTooltip from '@/components/Elements/NextMatches/TeamFuerzaTooltip';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'ResumeTooltipModal',
  components: {
    Tooltip,
    TeamFuerzaTooltip,
  },
  props: {
    show: {
      type: Boolean,
      required: false,
    },
    showClose: {
      type: Boolean,
      required: false,
      default: true,
    },
    dataLocal: {
      type: Object,
      required: true,
    },
    dataVisiting: {
      type: Object,
      required: true,
    },
    names: {
      type: Object,
      required: true,
    },
    keyName: {
      type: String,
      required: true,
    },
    leagueAvg: {
      type: [String, Number],
      required: true,
    },
    local: {
      type: Boolean,
      required: false,
    },
    left: {
      type: Boolean,
      required: false,
      default: true,
    },
    x: {
      type: Number,
      required: true,
    },
    y: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      defaultTeam: { team_name: '', logo: '' },
    };
  },
  computed: {
    ...mapState('nextGeneral', ['gameForAnalysis', 'currentSeasonTeams', 'showLiveData']),
    ...mapGetters('nextGeneral', ['homeTeamStatisticsType', 'visitingTeamStatisticsType', 'showPostMatchData']),
    isMobile() {
      return this.$mq === 'mobile';
    },
    localCategoryName() {
      var KEY = `${this.keyName[0]}-1`;
      if (this.names[KEY] && this.names[KEY] != '') {
        if (
          this.names[KEY].split('/')[0] == 'Faltas 1' ||
          this.names[KEY].split('/')[0] == 'Faltas 2' ||
          this.names[KEY].split('/')[0] == 'Faltas 3' ||
          this.names[KEY].split('/')[0] == 'Faltas 4'
        ) {
          return Object.keys(this.names).length
            ? this.names[KEY].split('/')[0] + '/' + this.names[KEY].split('/')[1]
            : '';
        } else {
          return Object.keys(this.names).length ? this.names[KEY].split('/')[0] : '';
        }
      } else {
        KEY = '0-0';
        return Object.keys(this.names).length ? this.names[KEY] : '';
      }
    },
    visitingCategoryName() {
      var KEY = `${this.keyName[2]}-1`;
      if (this.names[KEY] && this.names[KEY] != '') {
        if (
          this.names[KEY].split('/')[0] == 'Faltas 1' ||
          this.names[KEY].split('/')[0] == 'Faltas 2' ||
          this.names[KEY].split('/')[0] == 'Faltas 3' ||
          this.names[KEY].split('/')[0] == 'Faltas 4'
        ) {
          return Object.keys(this.names).length
            ? this.names[KEY].split('/')[0] + '/' + this.names[KEY].split('/')[1]
            : '';
        } else {
          return Object.keys(this.names).length ? this.names[KEY].split('/')[0] : '';
        }
      } else {
        KEY = '0-0';
        return Object.keys(this.names).length ? this.names[KEY] : '';
      }
    },
    localTeam() {
      return this.currentSeasonTeams.find(team => team.team_id === this.gameForAnalysis.home_team) || this.defaultTeam;
    },
    visitingTeam() {
      return (
        this.currentSeasonTeams.find(team => team.team_id === this.gameForAnalysis.visiting_team) || this.defaultTeam
      );
    },
    localCategoryTeamLeader() {
      return this.currentSeasonTeams.find(team => team.team_id === this.dataLocal.leader.teamId) || this.defaultTeam;
    },
    visitingCategoryTeamLeader() {
      return this.currentSeasonTeams.find(team => team.team_id === this.dataVisiting.leader.teamId) || this.defaultTeam;
    },
    totalTeams() {
      return this.currentSeasonTeams.length;
    },
    conclusion() {
      if (!this.showPostMatchData) {
        const homeTeamRanking =
          this.homeTeamStatisticsType === 'accomplished'
            ? this.dataLocal.ranking
            : this.totalTeams + 1 - this.dataLocal.ranking;
        const visitingTeamRanking =
          this.visitingTeamStatisticsType === 'accomplished'
            ? this.dataVisiting.ranking
            : this.totalTeams + 1 - this.dataVisiting.ranking;
        let teamName = homeTeamRanking < visitingTeamRanking ? this.localTeam.team_name : this.visitingTeam.team_name;
        let conclusion = `${teamName} tiene más fuerza en ésta categoría.`;
        if (homeTeamRanking === visitingTeamRanking)
          conclusion = 'Ambos equipos tienen igual fuerza en ésta comparación.';
        return conclusion;
      } else {
        const totalCat = this.dataLocal.totalPostGame + this.dataVisiting.totalPostGame + 1;
        const homeTeamRanking = totalCat - this.dataLocal.totalPostGame;
        const visitingTeamRanking = totalCat - this.dataVisiting.totalPostGame;
        let teamName = homeTeamRanking < visitingTeamRanking ? this.localTeam.team_name : this.visitingTeam.team_name;
        let conclusion = `${teamName} tiene más fuerza en ésta categoría.`;
        if (homeTeamRanking === visitingTeamRanking)
          conclusion = 'Ambos equipos tienen igual fuerza en ésta comparación.';
        return conclusion;
      }
    },
  },
  methods: {
    onClose() {
      this.$emit('update:show', false);
    },
  },
};
</script>

<style scoped>
.team-info {
  margin-top: 12px;
  align-content: center;
  text-align: center;
}
.local {
  margin-left: 10px;
}
.visiting {
  margin-right: 10px;
}
.conclusion {
  margin: 5px 15px 10px 15px;
  align-content: center;
  text-align: center;
  font-size: 13px;
}
.v-bar {
  width: 1px;
  height: 150px;
  border-right: 1px solid dimgrey;
  margin: 5px 10px 5px 20px;
}
td.td-v-bar {
  padding-left: 0;
}
@media only screen and (max-width: 400px) {
  .team-info {
    margin-top: 8px;
    align-content: center;
    text-align: center;
  }
  .local {
    margin-left: 5px;
  }
  .visiting {
    margin-right: 5px;
  }
  .v-bar {
    width: 1px;
    height: 125px;
    border-right: 1px solid dimgrey;
    margin: 5px 5px 5px 5px;
  }
  .td-v-bar {
    padding: 0;
  }
  .td-team-info {
    padding: 2px 5px 2px 5px;
  }
}
</style>
