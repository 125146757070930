<template>
  <div class="statsFiltered-container">
    <div class="is-flex">
      <figure class="image is-24x24 is-inline-flex is-marginless">
        <img :src="team.logo" alt="team logo" />
      </figure>
      <span class="team-name"> {{ team.team_name }}</span>
    </div>
    <template v-if="!showPostMatchData">
      <div>
        Promedio {{ name }}:
        <span>{{ data.avgPostGame | toFixed2 }}</span>
      </div>
      <div v-if="showLiveData">
        LIVE:
        <span>{{ data.totalLive | toFixed2 }}</span>
      </div>
      <div class="is-hidden">
        Promedio {{ tournamentSelected.name }}: <span>{{ leagueAvg }}</span>
      </div>
      <div>
        Ranking: <span>{{ data.ranking }}</span>
      </div>
      <div class="is-flex is-hidden">
        Líder en esta categoría:
        <span>
          <figure class="image is-24x24 is-inline-flex is-marginless leader-logo" :title="teamLeader.team_name">
            <img :src="teamLeader.logo" alt="leader logo" />
          </figure>
        </span>
      </div>
    </template>
    <template v-else>
      <div>
        {{ name }}:
        <span>{{ data.totalPostGame }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
export default {
  name: 'TeamFuerzaTooltip',
  props: {
    team: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    teamLeader: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    leagueAvg: {
      type: String,
      required: true,
    },
    showLiveData: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('nextGeneral', ['showPostMatchData']),
    ...mapState('general', ['tournamentSelected']),
  },
};
</script>

<style scoped>
.statsFiltered-container {
  margin-top: 12px;
  margin-left: 20px;
  font-family: 'Avenir-Demi';
  font-size: 11px;
  line-height: 2.17;
  text-align: left;
}
.statsFiltered-container div > span {
  font-family: 'Avenir-Regular';
}
.team-name {
  margin-left: 8px;
  font-family: 'Avenir-Demi' !important;
  font-size: 13px;
  font-weight: bold;
}
.leader-logo {
  margin-left: 8px !important;
}
div {
  margin-bottom: 8px;
}

@media only screen and (max-width: 400px) {
  .statsFiltered-container {
    margin-top: 3px;
    margin-left: 5px;
    font-size: 11px;
    line-height: 1.8;
    text-align: left;
  }
  .team-name {
    margin: 6px 0 0 6px;
    font-size: 12px !important;
    font-weight: bold;
  }
  .leader-logo {
    margin-left: 4px !important;
  }
}
</style>
