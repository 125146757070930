<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 10" xmlns:xlink="http://www.w3.org/1999/xlink">
      <!-- Linea base -->
      <line x1="0" y1="5" x2="240" y2="5" stroke="silver" stroke-width="2" stroke-dasharray="5,5" />
      <line x1="250" y1="1" x2="250" y2="10" stroke="black" stroke-width="2" stroke-style="" />
      <line x1="260" y1="5" x2="500" y2="5" stroke="silver" stroke-width="2" stroke-dasharray="5,5" />

      <!-- Flechas para 0 -->
      <polygon v-if="postmatch == 0" points="247,1 240,5 247,10" stroke="#3bd8f1" stroke-width="1" fill="#3bd8f1" />
      <polygon v-if="postmatch == 0" points="253,1 260,5 253,10" stroke="#3bd8f1" stroke-width="1" fill="#3bd8f1" />

      <!-- Postmatch -->
      <polygon
        v-if="postmatch < 0 && postmatch > -10"
        points="247,1 240,5 247,10"
        stroke="#3bd8f1"
        stroke-width="1"
        fill="#3bd8f1"
      />

      <polygon
        v-if="postmatch > 0 && postmatch < 10"
        points="253,1 260,5 253,10"
        stroke="#3bd8f1"
        stroke-width="1"
        fill="#3bd8f1"
      />

      <line
        v-if="postmatch >= 10"
        x1="250"
        y1="5"
        :x2="250 + postmatch * 2.5"
        y2="5"
        stroke="#3bd8f1"
        stroke-width="2"
        fill="#3bd8f1"
        stroke-dasharray="5,5"
      />

      <line
        v-if="postmatch <= -10"
        x1="246"
        y1="5"
        :x2="248 + postmatch * 2.5"
        y2="5"
        stroke="#3bd8f1"
        stroke-width="2"
        fill="#3bd8f1"
        stroke-dasharray="5,5"
      />

      <polygon
        v-if="postmatch >= 10"
        :points="`${250 + postmatch * 2.5 - 10},1 ${250 + postmatch * 2.5},5 ${250 + postmatch * 2.5 - 10},10`"
        stroke="#3bd8f1"
        stroke-width="1"
        fill="#3bd8f1"
      />
      <polygon
        v-if="postmatch <= -10"
        :points="`${250 + postmatch * 2.5 + 10},1 ${250 + postmatch * 2.5},5 ${250 + postmatch * 2.5 + 10},10`"
        stroke="#3bd8f1"
        stroke-width="1"
        fill="#3bd8f1"
      />

      <!-- Live -->
      <line
        v-if="live >= 10 && isLive && showLiveData"
        x1="250"
        y1="5"
        :x2="250 + live * 2.5"
        y2="5"
        stroke="#89d61b"
        stroke-width="2"
        fill="#89d61b"
        stroke-dasharray="5,5"
      />
      <line
        v-if="live <= -10 && isLive && showLiveData"
        x1="246"
        y1="5"
        :x2="248 + live * 2.5"
        y2="5"
        stroke="#89d61b"
        stroke-width="2"
        fill="#89d61b"
        stroke-dasharray="5,5"
      />

      <!--   If live is zero, arrows must be in the center.   -->
      <polygon
        v-if="live >= 0 && isLive && showLiveData"
        :points="`${250 + (live * 2.5 || 12) - 10},1 ${250 + (live * 2.5 || 12)},5 ${250 + (live * 2.5 || 12) - 10},10`"
        stroke="#89d61b"
        stroke-width="1"
        fill="#89d61b"
      />

      <!--   If live is zero, arrows must be in the center.   -->
      <polygon
        v-if="live <= 0 && isLive && showLiveData"
        :points="
          `${250 + (live * 2.5 || -12) + 10},1 ${250 + (live * 2.5 || -12)},5 ${250 + (live * 2.5 || -12) + 10},10`
        "
        stroke="#89d61b"
        stroke-width="1"
        fill="#89d61b"
      />
    </svg>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Fuerza',
  props: {
    postmatch: {
      type: Number,
      required: true,
    },
    live: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    ...mapGetters('nextGeneral', ['isLive', 'showLiveData']),
  },
};
</script>
